import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  PanelType,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { explorePage } from './components/explore/ExplorePage';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import InfoIcon from '@material-ui/icons/Info';
import PhoneIcon from '@material-ui/icons/Phone';
import { CustomTechDocsHome } from './components/techdocs/CustomTechDocsHome';
import { Entity } from '@backstage/catalog-model';
import { EntitySnykContent } from 'backstage-plugin-snyk';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import {
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import { EntityCriticalityPicker, EntityPersonalDataPicker } from '@internal/backstage-plugin-vorwerk';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props =>
      process.env.NODE_ENV === 'development' ? (
        <SignInPage {...props} auto providers={['guest']} />
      ) : (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'okta-auth-provider',
            title: 'Okta',
            message:
              "If you can't sign in, reach out to your People Manager or on #backstage-developer-portal in Slack",
            apiRef: oktaAuthApiRef,
          }}
        />
      ),
  },
  icons: {
    info: InfoIcon,
    phone: PhoneIcon,
  },
});

const techDocsTabsConfig = [
  {
    label: 'Documentation',
    panels: [
      {
        title: 'Documentation',
        description: 'All the docs',
        panelType: 'DocsTable' as PanelType,
        filterPredicate: 'all',
      },
    ],
  },
  {
    label: 'Paved paths',
    panels: [
      {
        title: 'Paved paths',
        description: 'Documentation about standards to follow',
        panelType: 'DocsCardGrid' as PanelType,
        filterPredicate: (entity: Entity) =>
          entity?.metadata?.tags?.includes('paved-path') ?? false,
      },
    ],
  },
];

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      {HomePage}
    </Route>
    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          pagination
          filters={
            <>
              <EntityKindPicker />
              <EntityTypePicker />
              <UserListPicker initialFilter="all" />
              <EntityOwnerPicker />
              <EntityLifecyclePicker />
              <EntityCriticalityPicker />
              <EntityPersonalDataPicker />
              <EntityTagPicker />
            </>
          }
        />
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomTechDocsHome tabsConfig={techDocsTabsConfig} />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          width={1300}
          height={600}
          title="Digital R&D Tech Radar"
          pageTitle=""
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/snyk" element={<EntitySnykContent />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
